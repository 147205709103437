<template>
  <div>
      <div class="container-fluid top">
        <div class="col-md-10 offset-md-1">
            <b-card no-body class="b-card">
                <b-tabs card >
                <b-tab title="Configuración 1" active >
                    <!-- card1 -->
                    <div class="row ">
                        <div class="col-md-10 offset-1 fondo" >
                            <h2 class="mb-5">{{rows[0].title}}</h2>
                            <b-form>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[0].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                            id="input-1"
                                            v-model="rows[0].price"
                                            >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[1].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[1].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[2].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[2].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                            </b-form>
                        </div>
                    </div>
                    <!-- card2 -->
                    <div class="row mt-2">
                        <div class="col-md-10 offset-1 fondo" >
                            <h2 class="mb-5">{{rows[3].title}}</h2>
                            <b-form  >
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[3].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                            id="input-1"
                                            v-model="rows[3].price"
                                            >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[4].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[4].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[5].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[5].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[6].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[6].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                            </b-form>
                        </div>
                    </div>
                    <!-- card3 -->
                    <div class="row mt-2">
                        <div class="col-md-10 offset-1 fondo" >
                            <h2 class="mb-5">{{rows[7].title}}</h2>
                            <b-form  >
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[7].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                            id="input-1"
                                            v-model="rows[7].price"
                                            >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[8].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[8].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[9].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[9].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                            </b-form>
                        </div>
                    </div>
                    <!-- card4 -->
                    <div class="row mt-2">
                        <div class="col-md-10 offset-1 fondo" >
                            <h2 class="mb-5">{{rows[10].title}}</h2>
                            <b-form  >
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[10].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                            id="input-1"
                                            v-model="rows[10].price"
                                            >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[11].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[11].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[12].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[12].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[13].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[13].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                            </b-form>
                        </div>
                    </div>

                </b-tab>
                <b-tab title="Configuración 2" >
                    <!-- card5 -->
                    <div class="row ">
                        <div class="col-md-10 offset-1 fondo" >
                            <h2 class="mb-5">{{rows[14].title}}</h2>
                            <b-form >
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[14].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                            id="input-1"
                                            v-model="rows[14].price"
                                            >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[15].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[15].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[16].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[16].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                            </b-form>
                        </div>
                    </div>
                    <!-- card6 -->
                    <div class="row mt-2">
                        <div class="col-md-10 offset-1 fondo" >
                            <h2 class="mb-5">{{rows[17].title}}</h2>
                            <b-form  >
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[17].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                            id="input-1"
                                            v-model="rows[17].price"
                                            >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[18].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[18].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[19].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[19].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                            </b-form>
                        </div>
                    </div>
                    <!-- card7 -->
                    <div class="row mt-2">
                        <div class="col-md-10 offset-1 fondo" >
                            <h2 class="mb-5">{{rows[20].title}}</h2>
                            <b-form  >
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[20].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                            id="input-1"
                                            v-model="rows[20].price"
                                            >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[21].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[21].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[22].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[22].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                            </b-form>
                        </div>
                    </div>
                    <!-- card8 -->
                    <div class="row mt-2">
                        <div class="col-md-10 offset-1 fondo" >
                            <h2 class="mb-5">{{rows[23].title}}</h2>
                            <b-form  >
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[23].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                            id="input-1"
                                            v-model="rows[23].price"
                                            >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[24].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[24].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[25].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[25].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>

                            </b-form>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Configuración 3">

                    <!-- card9 -->
                    <div class="row ">
                        <div class="col-md-10 offset-1 fondo" >
                            <h2 class="mb-5">{{rows[26].title}}</h2>
                            <b-form >
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[26].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                            id="input-1"
                                            v-model="rows[26].price"
                                            >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[27].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[27].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[28].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[28].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[29].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[29].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                            </b-form>
                        </div>
                    </div>
                    <!-- card10 -->
                    <div class="row mt-2">
                        <div class="col-md-10 offset-1 fondo" >
                            <h2 class="mb-5">{{rows[30].title}}</h2>
                            <b-form  >
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[30].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                            id="input-1"
                                            v-model="rows[30].price"
                                            >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[31].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[31].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[32].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[32].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                            </b-form>
                        </div>
                    </div>
                    <!-- card11 -->
                    <div class="row mt-2">
                        <div class="col-md-10 offset-1 fondo" >
                            <h2 class="mb-5">{{rows[33].title}}</h2>
                            <b-form  >
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[33].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                            id="input-1"
                                            v-model="rows[33].price"
                                            >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[34].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[34].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[35].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[35].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                            </b-form>
                        </div>
                    </div>
                    <!-- card12 -->
                    <div class="row mt-2">
                        <div class="col-md-10 offset-1 fondo" >
                            <h2 class="mb-5">{{rows[36].title}}</h2>
                            <b-form  >
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[36].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                            id="input-1"
                                            v-model="rows[36].price"
                                            >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[37].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[37].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="rows[38].description"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                                id="input-1"
                                                v-model="rows[38].price"
                                                >
                                        </b-form-input>
                                    </div>  
                                </div>

                            </b-form>
                        </div>
                    </div>


                </b-tab>
                <b-tab title="whatsapp">
                    <!-- whatsapp -->
                    <div class="row ">
                        <div class="col-md-10 offset-1 fondo" >
                            <h2 class="mb-5">Configuración Whatsapp</h2>
                            <b-form >
                                <div class="row mt-3">
                                    <div class="col-md-8">
                                        <b-form-group id="input-group-1"
                                        :label="config.title"
                                        label-for="input-1" class="text">
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-4">
                                        <b-form-input placeholder=" "
                                            id="input-1"
                                            v-model="numberWhatsapp"
                                            >
                                        </b-form-input>
                                    </div>  
                                </div>
                            </b-form>
                        </div>
                    </div>

                </b-tab>

                </b-tabs>
                <div class="row">
                    <div class="col-md-12">
                        <button :disabled="loading" class="btn color-b mt-3" @click="saveData"> Guardar</button>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-12">
                        <b-spinner v-if="loading" label="Loading..."></b-spinner>
                    </div>
                </div>
            </b-card>
        </div>
      </div>
  </div>
</template>

<script>
 export default {
    data() {
      return {
        form: {
          name: '',
        },
        foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
        //show: true,
        //////////
        rows:[],
        loading: false,
        config: {},
        numberWhatsapp: ''
      }
    },
    async mounted(){
        await this.getData();
        await this.getConfig();
    },
    methods: {
      async getConfig(){
          try {
              let req = await fetch(this.$store.state.url + "config/1")
              let config = await req.json()
              this.config = config
              this.numberWhatsapp = config.data
              console.log('rr', this.config)
              //this.whatsapp = this.config.data
          } catch (error) {
              console.log('error', error)
          }
      },        
        async getData(){
            let req = await fetch(this.$store.state.url + "wizardone")
            let rows = await req.json()
            this.rows = rows
            console.log('rr', this.rows)
            //this.rowsBase  = [...this.rows]
        },
        async saveData(){
            try {
                let methodRequest
                let msg
                let path
                methodRequest= 'put'
                path = 'wizardone'
                msg = 'Datos actualizados correctamente.'

                let options = {
                    method: methodRequest,
                    headers: { 'Content-Type': 'application/json'},
                    body: JSON.stringify(this.rows)
                }
                this.loading = true
                let req = await fetch(this.$store.state.url + path, options)
                if (req.ok) {
                    // this.loading = false
                    // alert(msg)
                }
                
                let optionsC = {
                    method: 'put',
                    headers: { 'Content-Type': 'application/json'},
                    body: JSON.stringify({data: this.numberWhatsapp})
                }
                this.loading = true
                req = await fetch(this.$store.state.url + 'config/1', optionsC)
                if (req.ok) {
                    this.loading = false
                    alert(msg)
                }

            }
            catch(err){
                this.loading = false
                console.log(err)
            }
        },
      onSubmit(event) {
        event.preventDefault()
        alert(JSON.stringify(this.form))
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.food = null
        this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    }
  }
</script>

<style>
.top{
    margin-top: 1%;
}

.text{
    text-align: left;
    font-size: 22px;
}
.fondo{
    background-color: rgb(240, 240, 240);
    border-radius: 30px;
    padding: 3%;
}
.color-b{
      background-color: rgb(24, 195, 90);
      color: rgb(242, 212, 251);
      border-radius: 10px;
      width: 300px;
      height: 50px;
      font-size: 25px;
      /* margin-bottom: 30%; */
      
  }
  .b-card{
      height: 30%;
  }

</style>